import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'tela-inicial-branca', pathMatch: 'full' },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'alert',
    loadChildren: () => import('./pages/alert/alert.module').then( m => m.AlertPageModule)
  },
  {
    path: 'action-sheet',
    loadChildren: () => import('./pages/action-sheet/action-sheet.module').then( m => m.ActionSheetPageModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('./pages/cards/cards.module').then( m => m.CardsPageModule)
  },
  {
    path: 'datas',
    loadChildren: () => import('./pages/datas/datas.module').then( m => m.DatasPageModule)
  },
  {
    path: 'grid',
    loadChildren: () => import('./pages/grid/grid.module').then( m => m.GridPageModule)
  },
  {
    path: 'input',
    loadChildren: () => import('./pages/input/input.module').then( m => m.InputPageModule)
  },
  {
    path: 'textarea',
    loadChildren: () => import('./pages/textarea/textarea.module').then( m => m.TextareaPageModule)
  },
  {
    path: 'slides',
    loadChildren: () => import('./pages/slides/slides.module').then( m => m.SlidesPageModule)
  },
  {
    path: 'toast',
    loadChildren: () => import('./pages/toast/toast.module').then( m => m.ToastPageModule)
  },
  {
    path: 'addcliente',
    loadChildren: () => import('./pages/addcliente/addcliente.module').then( m => m.AddclientePageModule)
  },
  {
    path: 'cliente',
    loadChildren: () => import('./pages/cliente/cliente.module').then( m => m.ClientePageModule)
  },
  {
    path: 'mostrarcliente',
    loadChildren: () => import('./pages/mostrarcliente/mostrarcliente.module').then( m => m.MostrarclientePageModule)
  },
   {
    path: 'cliente',
    loadChildren: () => import('./pages/cliente/cliente.module').then( m => m.ClientePageModule)
  },
  {
    path: 'addcliente/:id/:nome/:telefone/:email',
    loadChildren: () => import('./pages/addcliente/addcliente.module').then( m => m.AddclientePageModule)
  },
  {
    path: 'mostrarcliente/:id/:nome/:telefone/:email',
    loadChildren: () => import('./pages/mostrarcliente/mostrarcliente.module').then( m => m.MostrarclientePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'perfil-cliente',
    loadChildren: () => import('./pages/perfil-cliente/perfil-cliente.module').then( m => m.PerfilClientePageModule)
  },
  {
    path: 'meuperfil',
    loadChildren: () => import('./pages/meuperfil/meuperfil.module').then( m => m.MeuperfilPageModule)
  },
  {
    path: 'meuperfil/:senha_perfil',
    loadChildren: () => import('./pages/meuperfil/meuperfil.module').then( m => m.MeuperfilPageModule)
  },
  {
    path: 'editarperfil',
    loadChildren: () => import('./pages/editarperfil/editarperfil.module').then( m => m.EditarperfilPageModule)
  },
  {
    path: 'perfil-cliente/:id/:nome/:telefone',
    loadChildren: () => import('./pages/perfil-cliente/perfil-cliente.module').then( m => m.PerfilClientePageModule)
  },
  {
    path: 'editarperfil/:id/:nome/:telefone',
    loadChildren: () => import('./pages/editarperfil/editarperfil.module').then( m => m.EditarperfilPageModule)
  },
  {
    path: 'editarmeuendereco',
    loadChildren: () => import('./pages/editarmeuendereco/editarmeuendereco.module').then( m => m.EditarmeuenderecoPageModule)
  },
  {
    path: 'editarmeuendereco/:id/:endereco/:cidade/:bairro/:numero/:quadra/:lote/:complemento/:ponto_de_referencia',
    loadChildren: () => import('./pages/editarmeuendereco/editarmeuendereco.module').then( m => m.EditarmeuenderecoPageModule)
  },
  {
    path: 'inf-depositoe-produto',
    loadChildren: () => import('./pages/inf-depositoe-produto/inf-depositoe-produto.module').then( m => m.InfDepositoeProdutoPageModule)
  },
  {
    path: 'confirmacao-dos-dados-do-cliente',
    loadChildren: () => import('./pages/confirmacao-dos-dados-do-cliente/confirmacao-dos-dados-do-cliente.module').then( m => m.ConfirmacaoDosDadosDoClientePageModule)
  },
  {
    path: 'pg-confimacao-da-compra',
    loadChildren: () => import('./pages/pg-confimacao-da-compra/pg-confimacao-da-compra.module').then( m => m.PgConfimacaoDaCompraPageModule)
  },
  {
    path: 'inf-depositoe-produto/:senha_perfil/:id_do_deposito/:nome_do_deposito/:tempo_minimo/:tempo_maximo/:preco/:produto/:email_do_deposito/:telefone_do_deposito',
    loadChildren: () => import('./pages/inf-depositoe-produto/inf-depositoe-produto.module').then( m => m.InfDepositoeProdutoPageModule)
  },
  {
    path: 'pg-confimacao-da-compra/:senha_perfil/:id_do_deposito/:nome_do_deposito/:tempo_minimo/:tempo_maximo/:preco/:produto/:marca/:email_do_deposito/:telefone_do_deposito',
    loadChildren: () => import('./pages/pg-confimacao-da-compra/pg-confimacao-da-compra.module').then( m => m.PgConfimacaoDaCompraPageModule)
  },
  {
    path: 'confirmacao-dos-dados-do-cliente/:senha_perfil/:id_do_deposito/:nome_do_deposito/:tempo_minimo/:tempo_maximo/:preco/:total/:quantidade/:dinheiro_ou_cartao/:debitoOuCredito/:troco/:cpf_ou_cnpj_sim_ou_nao/:text_cpf_ou_cnpj',
    loadChildren: () => import('./pages/confirmacao-dos-dados-do-cliente/confirmacao-dos-dados-do-cliente.module').then( m => m.ConfirmacaoDosDadosDoClientePageModule)
  },
  {
    path: 'primeiro-cadastro',
    loadChildren: () => import('./pages/primeiro-cadastro/primeiro-cadastro.module').then( m => m.PrimeiroCadastroPageModule)
  },
  {
    path: 'politica',
    loadChildren: () => import('./pages/politica/politica.module').then( m => m.PoliticaPageModule)
  },
  {
    path: 'mudar-endereco',
    loadChildren: () => import('./pages/mudar-endereco/mudar-endereco.module').then( m => m.MudarEnderecoPageModule)
  },
  {
    path: 'tela-inicial-branca',
    loadChildren: () => import('./pages/tela-inicial-branca/tela-inicial-branca.module').then( m => m.TelaInicialBrancaPageModule)
  },
  {
    path: 'ler-plitica',
    loadChildren: () => import('./pages/ler-plitica/ler-plitica.module').then( m => m.LerPliticaPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
